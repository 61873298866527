import { Link } from '@remix-run/react'
import { X } from 'lucide-react'
import { useState, type MouseEvent } from 'react'
import { type DateRange } from 'react-day-picker'
import { cn } from '#app/utils/misc.tsx'
import { formatDateRange } from '#server/helpers/date-range.ts'
import { IconButton } from '../icon-button'
import { CardPreviewGallery, CardPreviewImage } from './card-preview-image'
import { CardPreviewTitle } from './card-preview-title'

interface ItineraryDocumentPreviewProps {
  className?: string
  id: string
  title: string
  dateRange?: DateRange
  numDays?: number
  thumbnailPhotos?: string[] | null
  onClick?: (e: MouseEvent) => void
  onDelete?: (e: MouseEvent) => void
}

export const ItineraryDocumentPreview = ({
  className,
  id,
  title,
  dateRange,
  numDays,
  thumbnailPhotos,
  onClick,
  onDelete,
}: ItineraryDocumentPreviewProps) => {
  const [hovered, setHovered] = useState(false)

  return (
    <div
      className={cn('relative flex flex-1 flex-col', className)}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <ItineraryDocumentImagePreview
        id={id}
        thumbnailPhotos={thumbnailPhotos}
        onClick={onClick}
      />
      <CardPreviewTitle className="line-clamp-none" text={title} />
      {dateRange != null && (
        <div className="text-base">{formatDateRange(dateRange)}</div>
      )}
      {numDays != null && (
        <div className="text-base">
          {`${numDays} day${numDays !== 1 ? 's' : ''} planned`}
        </div>
      )}

      {onDelete != null && hovered && (
        <IconButton
          className="border-border absolute -right-2 -top-2 border-2 bg-white shadow-md"
          variant="circle"
          onClick={onDelete}
        >
          <X className="h-4 w-4" />
        </IconButton>
      )}
    </div>
  )
}

interface ItineraryDocumentImagePreviewProps {
  id: string
  thumbnailPhotos?: string[] | null
  onClick?: (e: MouseEvent) => void
}

const ItineraryDocumentImagePreview = ({
  id,
  thumbnailPhotos,
  onClick,
}: ItineraryDocumentImagePreviewProps) => {
  if (onClick != null) {
    return (
      <div className="mb-4 flex-1 hover:cursor-pointer">
        <CardPreviewImage
          className="h-full"
          photoSrc={thumbnailPhotos?.[0]}
          onClick={onClick}
        />
      </div>
    )
  }

  return (
    <nav className="mb-4 flex-1">
      <Link to={`/itinerary/${id}`} prefetch="intent" preventScrollReset>
        {thumbnailPhotos && thumbnailPhotos.length >= 4 ? (
          <CardPreviewGallery
            className="h-full auto-rows-[129px]"
            thumbnailPhotos={thumbnailPhotos}
          />
        ) : (
          <CardPreviewImage
            className="h-full"
            photoSrc={thumbnailPhotos?.[0]}
          />
        )}
      </Link>
    </nav>
  )
}
