import { format } from 'date-fns'
import { type DateRange } from 'react-day-picker'

export const formatDateRange = (dateRange: DateRange): string => {
  if (dateRange.from == null) {
    return ''
  }

  if (dateRange.to != null) {
    if (dateRange.from.getFullYear() === dateRange.to.getFullYear()) {
      return `${format(dateRange.from, 'MMM dd')} - ${format(dateRange.to, 'MMM dd, yyyy')}`
    }
    return `${format(dateRange.from, 'MMM dd, yyyy')} - ${format(dateRange.to, 'MMM dd, yyyy')}`
  }

  return format(dateRange.from, 'MMM dd, yyyy')
}
